import Box from '@mui/material/Box';
import MuiTab, { TabProps } from '@mui/material/Tab';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import React, { ReactNode, SyntheticEvent, useState } from 'react';

export interface TabsProps extends MuiTabsProps {
  data: Array<{ label: string; content: ReactNode }>;
  tabProps?: TabProps;
  initialIndex?: number;
  onTabChange?: (newTab: number) => void;
  separateMode?: boolean;
}

export function Tabs({ data, tabProps, initialIndex = 0, onTabChange, ...props }: TabsProps) {
  const [value, setValue] = useState(initialIndex);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChange?.(newValue);
  };

  return (
    <div className="reusable-tabs">
      <Box className='reusable-tabs-container' sx={{ borderBottom: 1, borderColor: '#EEEFF2' }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          sx={{
            '.MuiTabs-flexContainer': {
              gap: { xs: '24px', sm: '40px' },
              px: { xs: '24px', sm: '40px' },
            },

            '.MuiTabs-indicator': {
              height: '6px',
              backgroundColor: '#307FF6',
            },
          }}
          {...props}
        >
          {data.map((tab, index) => (
            <MuiTab
              key={index}
              label={tab.label}
              sx={{
                px: 0,
                py: '14px',
                minWidth: 0,

                fontSize: { xs: 16, sm: 18 },
                fontWeight: 500,
                letterSpacing: 0.36,
                color: '#6C6C6C',
                textTransform: 'none',
                lineHeight: 'normal',
                fontFamily: 'ploni, sans-serif',

                '&.Mui-selected': {
                  color: '#2F80ED',
                  fontWeight: 600,
                },
              }}
              {...tabProps}
            />
          ))}
        </MuiTabs>
      </Box>

      {data.map((tab, index) => (
        <TabPanel key={index} index={index} value={value}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
}

interface TabPanelProps {
  index: number;
  value: number;
  children: ReactNode;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box
          className="tabpanel-container"
          sx={{ padding: { xs: '24px', sm: '40px' }, fontFamily: 'ploni, sans-serif' }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
